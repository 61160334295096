import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link, useLocation } from 'react-router-dom';
import App from './App';
import TrezorTokenTransferPage from './TrezorTokenTransferPage';

const Navigation = () => {
  const location = useLocation();

  return (
    <nav className="bg-gray-800 p-4">
      <div className="container mx-auto">
        <ul className="flex space-x-4">
          <li>
            <Link
              to="/"
              className={`text-white hover:text-gray-300 transition ${
                location.pathname === '/' ? 'font-bold' : ''
              }`}
            >
              MetaMask Transfer
            </Link>
          </li>
          <li>
            <Link
              to="/trezor"
              className={`text-white hover:text-gray-300 transition ${
                location.pathname === '/trezor' ? 'font-bold' : ''
              }`}
            >
              Trezor Transfer
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  );
};

const AppRoutes: React.FC = () => (
  <Router>
    <div className="min-h-screen bg-gray-100">
      <Navigation />
      <div className="container mx-auto mt-8 p-4">
        <Routes>
          <Route path="/" element={<App />} />
          <Route path="/trezor" element={<TrezorTokenTransferPage />} />
        </Routes>
      </div>
    </div>
  </Router>
);

export default AppRoutes;