import React, { useState, useEffect } from 'react';
import { ethers } from 'ethers';
import { PermitMessage, TokenInfo, TransactionRequest, TransactionResponse } from './types';
import config from './config';

const ERC20_ABI = [
  "function name() view returns (string)",
  "function symbol() view returns (string)",
  "function decimals() view returns (uint8)",
  "function balanceOf(address) view returns (uint)",
  "function approve(address spender, uint256 amount) returns (bool)",
  "function transfer(address to, uint256 amount) returns (bool)",
];

const ERC20Permit_ABI = [
  ...ERC20_ABI,
  "function nonces(address) view returns (uint)",
  "function permit(address owner, address spender, uint value, uint deadline, uint8 v, bytes32 r, bytes32 s)",
  "function DOMAIN_SEPARATOR() view returns (bytes32)"
];

const statusClases = {
  success: "bg-green-100",
  error: "bg-red-100",
  warning: "bg-yellow-100",
  info: "bg-blue-100",
};

const tokenConfig = {
  sepolia: {
    USDC: '0x1c7D4B196Cb0C7B01d743Fbc6116a902379C7238',
    USDT: '0x419Fe9f14Ff3aA22e46ff1d03a73EdF3b70A62ED'
  },
  mainnet: {
    USDC: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
    USDT: '0xdAC17F958D2ee523a2206206994597C13D831ec7'
  }
}

console.log({
  VITE_CONTRACT_ADDRESS: config.CONTRACT_ADDRESS,
})

const App: React.FC = () => {
  const [tokenAddress, setTokenAddress] = useState<string>('');
  const [receiverAddress, setReceiverAddress] = useState<string>('');
  const [amount, setAmount] = useState<string>('');
  const [fee, setFee] = useState<string>('');
  const [status, setStatus] = useState<{
    message: string;
    type: "success" | "error" | "warning" | "info";
  } | null>(null);
  const [tokenInfo, setTokenInfo] = useState<TokenInfo | null>(null);
  const [network, setNetwork] = useState<'sepolia' | 'mainnet' | null>(null);


  useEffect(() => {
    const detectNetwork = async () => {
      if (typeof window.ethereum !== 'undefined') {
        try {
          const provider = new ethers.BrowserProvider(window.ethereum);
          const network = await provider.getNetwork();
          const chainId = Number(network.chainId);
          console.log('chainId', chainId)
          if (chainId === 1) {
            setNetwork('mainnet');
          } else if (chainId === 11155111) {
            setNetwork('sepolia');
          } else {
            setNetwork(null);
            setStatus({
              message: 'Red no soportada. Por favor, conecta a Mainnet o Sepolia.',
              type: 'error'
            });
          }
        } catch (error) {
          console.error("Error detecting network:", error);
          setStatus({
            message: 'Error al detectar la red. Por favor, verifica tu conexión a MetaMask.',
            type: 'error'
          });
        }
      } else {
        setStatus({
          message: 'MetaMask no detectado. Por favor, instala MetaMask y recarga la página.',
          type: 'error'
        });
      }
    };
    detectNetwork();
  }, []);

  useEffect(() => {
    const fetchTokenInfo = async () => {
      if (!tokenAddress || !network) return;
      try {
        const provider = new ethers.BrowserProvider(window.ethereum);
        const contract = new ethers.Contract(tokenAddress, ERC20_ABI, provider);
        const name = await contract.name();
        const symbol = await contract.symbol();
        const decimals = Number(await contract.decimals());
        console.log('decimals', decimals)
        setTokenInfo({ name, symbol, decimals });
      } catch (error) {
        console.error("Error fetching token info:", error);
        setTokenInfo(null);
      }
    };
    fetchTokenInfo();
  }, [tokenAddress, network]);

  const handleSendTokens = async () => {
    if (!config.CONTRACT_ADDRESS) {
      setStatus({
        message: 'Error: Dirección del contrato no configurada. Contacte al administrador.',
        type: 'error'
      });
      return;
    }
    if (!network) {
      setStatus({
        message: 'Red no soportada o no detectada.',
        type: 'error'
      });
      return;
    }
    setStatus({
      message: 'Conectando a MetaMask...',
      type: 'info'
    });
    
    if (typeof window.ethereum === 'undefined') {
      setStatus({
        message: 'MetaMask no detectado. Por favor, instala MetaMask y recarga la página.',
        type: 'warning'
      });
      return;
    }

    try {
      await window.ethereum.request({ method: 'eth_requestAccounts' });
      const provider = new ethers.BrowserProvider(window.ethereum);
      const signer = await provider.getSigner();
      const userAddress = await signer.getAddress();

      if (!ethers.isAddress(userAddress)) {
        throw new Error('Dirección de usuario inválida');
      }

      if (!ethers.isAddress(receiverAddress)) {
        throw new Error('Dirección del receptor inválida');
      }

      setStatus({
        message: 'Conectado a MetaMask. Preparando transacción...',
        type: 'info'
      });
      const networkTokens = tokenConfig[network];
      const isUSDC = tokenAddress.toLowerCase() === networkTokens.USDC.toLowerCase();
      const isPermitToken = tokenAddress.toLowerCase() !== networkTokens.USDT.toLowerCase();
      const abi = isPermitToken ? ERC20Permit_ABI : ERC20_ABI;
      const tokenContract = new ethers.Contract(tokenAddress, abi, signer);

      if (!tokenInfo) {
        throw new Error('No se pudo obtener la información del token');
      }

      const value = ethers.parseUnits(amount, tokenInfo.decimals);
      const feeValue = ethers.parseUnits(fee, tokenInfo.decimals);
      const totalValue = value + feeValue;

      let signature, deadline;
      // let permitData = {};

      let transactionRequest: TransactionRequest = {
        environment: network,
        token: tokenAddress,
        sender: userAddress,
        receiver: receiverAddress,
        amount: value.toString(),
        fee: feeValue.toString(),
        deadline: deadline || 0,
      };

      if (isPermitToken) {
        const nonce = await tokenContract.nonces(userAddress);
        deadline = Math.floor(Date.now() / 1000) + 3600;
        const chainId = (await provider.getNetwork()).chainId;

        const domain = {
          name: tokenInfo.name,
          version: isUSDC ? '2' : '1',
          chainId: chainId,
          verifyingContract: tokenAddress
        };
        console.log('domain', domain);
        const types = {
          Permit: [
            { name: 'owner', type: 'address' },
            { name: 'spender', type: 'address' },
            { name: 'value', type: 'uint256' },
            { name: 'nonce', type: 'uint256' },
            { name: 'deadline', type: 'uint256' }
          ]
        };

        const message: PermitMessage = {
          owner: userAddress,
          spender: config.CONTRACT_ADDRESS,
          value: totalValue.toString(),
          nonce: nonce.toString(),
          deadline
        };

        console.log('message', message)

        setStatus({
          message: 'Firmando mensaje...',
          type: 'info'
        });
        signature = await signer.signTypedData(domain, types, message);
        const sig = ethers.Signature.from(signature);
        const permitData = {
          v: sig.v,
          r: sig.r,
          s: sig.s
        };
        transactionRequest = {
          ...transactionRequest,
          deadline,
          v: permitData.v,
          r: permitData.r,
          s: permitData.s
        };
      } else {
        // setStatus({
        //   message: 'Aprobando gasto de tokens...',
        //   type: 'info'
        // });
        
        // const tx = await tokenContract.approve(config.CONTRACT_ADDRESS, totalValue);
        // await tx.wait();
        setStatus({
          message: 'Solicitando aprobación para el gasto de tokens...',
          type: 'info'
        });
        
        try {
          const tx = await tokenContract.approve(config.CONTRACT_ADDRESS, totalValue);
          setStatus({
            message: 'Esperando confirmación de la aprobación...',
            type: 'info'
          });
          await tx.wait();
          setStatus({
            message: 'Aprobación confirmada. Preparando transacción...',
            type: 'info'
          });
          transactionRequest.isApproved = true;
        } catch (error) {
          if (error instanceof Error && error.message.includes('user rejected')) {
            throw new Error('Usuario rechazó la aprobación');
          } else {
            throw new Error('Error al aprobar el gasto de tokens');
          }
        }
      }

      setStatus({
        message: 'Enviando solicitud al relayer...',
        type: 'info'
      });

      const response = await fetch(`${config.BACKEND_URL}/relay`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(transactionRequest),
      });

      const result: TransactionResponse = await response.json();
      if (result.success) {
        setStatus({
          message: `Transacción enviada con éxito. Hash: ${result.transactionHash}`,
          type: 'success'
        });
      } else {
        setStatus({
          message: `Error: ${result.error}`,
          type: 'error'
        });
      }
    } catch (error) {
      console.error('Error:', error);
      if (error instanceof Error) {
        if (error.message.includes('user rejected')) {
          setStatus({
            message: 'Operación cancelada: El usuario rechazó la conexión o la firma',
            type: 'warning'
          });
        } else {
          setStatus({
            message: `Error: ${error.message}`,
            type: 'error'
          });
        }
      } else {
        setStatus({
          message: `Error desconocido: ${String(error)}`,
          type: 'error'
        });
      }
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 py-6 flex flex-col justify-center sm:py-12">
      <div className="relative py-3 sm:max-w-xl sm:mx-auto">
        <div className="absolute inset-0 bg-gradient-to-r from-cyan-400 to-light-blue-500 shadow-lg transform -skew-y-6 sm:skew-y-0 sm:-rotate-6 sm:rounded-3xl"></div>
        <div className="relative px-4 py-10 bg-white shadow-lg sm:rounded-3xl sm:p-20">
          <div className="max-w-md mx-auto">
            <div>
              <h1 className="text-2xl font-semibold text-center mb-6">Transferencia de Tokens sin Gas</h1>
              <p className="text-gray-600 text-center mb-4">Red actual: {network || 'No detectada'}</p>
            </div>
            <div className="divide-y divide-gray-200">
              <div className="py-4">
                <select 
                  className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                  value={tokenAddress} 
                  onChange={(e) => setTokenAddress(e.target.value)}
                >
                  <option value="">Selecciona un token</option>
                  {network && Object.entries(tokenConfig[network]).map(([name, address]) => (
                    <option key={address} value={address}>{name}</option>
                  ))}
                </select>
              </div>
              <div className="py-4">
                <input 
                  className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                  type="text" 
                  placeholder="Dirección del Receptor" 
                  value={receiverAddress} 
                  onChange={(e) => setReceiverAddress(e.target.value)} 
                />
              </div>
              <div className="py-4">
                <input 
                  className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                  type="text" 
                  placeholder="Cantidad" 
                  value={amount} 
                  onChange={(e) => setAmount(e.target.value)} 
                />
              </div>
              <div className="py-4">
                <input 
                  className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                  type="text" 
                  placeholder="Tarifa" 
                  value={fee} 
                  onChange={(e) => setFee(e.target.value)} 
                />
              </div>
              <div className="py-4">
                <button 
                  className="w-full px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={handleSendTokens}
                >
                  Enviar Tokens
                </button>
              </div>
            </div>
            {status && (
                    <div
                      className={`mb-6 p-4 rounded-md ${
                        statusClases[status.type] || ""
                      }`}
                    >
                      <h2 className="text-lg font-semibold mb-2">Estado</h2>
                      <p className="text-sm">{status.message}</p>
                    </div>
                  )}
            {tokenInfo && (
              <div className="mt-6 bg-gray-50 p-4 rounded-md">
                <h2 className="text-lg font-medium text-gray-900 mb-2">Información del Token</h2>
                <p className="text-sm text-gray-600">Nombre: {tokenInfo.name}</p>
                <p className="text-sm text-gray-600">Símbolo: {tokenInfo.symbol}</p>
                <p className="text-sm text-gray-600">Decimales: {tokenInfo.decimals}</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default App;