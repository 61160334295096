import React, { useState, useEffect, useCallback } from "react";
import TrezorConnect from "@trezor/connect-web";
import config from "./config";

import { ethers } from "ethers";

const ERC20_ABI = [
  "function name() view returns (string)",
  "function symbol() view returns (string)",
  "function decimals() view returns (uint8)",
  "function balanceOf(address) view returns (uint)",
  "function approve(address spender, uint256 amount) returns (bool)",
  "function transfer(address to, uint256 amount) returns (bool)",
];

const usdcAddresses = {
  mainnet: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
  sepolia: "0x1c7D4B196Cb0C7B01d743Fbc6116a902379C7238",
};

const tokenConfig = {
  sepolia: {
    USDC: usdcAddresses.sepolia,
    USDT: "0x419Fe9f14Ff3aA22e46ff1d03a73EdF3b70A62ED",
  },
  mainnet: {
    USDC: usdcAddresses.mainnet,
    USDT: "0xdAC17F958D2ee523a2206206994597C13D831ec7",
  },
};

const statusClases = {
  success: "bg-green-100",
  error: "bg-red-100",
  warning: "bg-yellow-100",
  info: "bg-blue-100",
};

const TrezorEIP2771Signing = () => {
  const [trezorConnected, setTrezorConnected] = useState(false);
  const [trezorAddress, setTrezorAddress] = useState("");
  const [tokenAddress, setTokenAddress] = useState(
    "0x1c7D4B196Cb0C7B01d743Fbc6116a902379C7238"
  );
  const [receiver, setReceiver] = useState(
    "0x70c7600e3876583Bc2c5068bA6eeF3Fb5550F6C0"
  );
  const [amount, setAmount] = useState("");
  const [fee, setFee] = useState("");
  const [deadline] = useState(`${Math.floor(Date.now() / 1000) + 3600}`);
  const [network, setNetwork] = useState("sepolia");
  const [status, setStatus] = useState<{
    message: string;
    type: "success" | "error" | "warning" | "info";
  } | null>(null);
  const [isLoadingTokenInfo, setIsLoadingTokenInfo] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [balanceInfo, setBalanceInfo] = useState<string | null>();

  const [tokenInfo, setTokenInfo] = useState<{
    name: string;
    symbol: string;
    decimals: number;
  } | null>();

  const getRpcUrl = () => {
    console.log("config", config);
    return network === "sepolia"
      ? config.SEPOLIA_RPC_URL
      : config.MAINNET_RPC_URL;
  };

  useEffect(() => {
    const totalBalance = Number(amount || "0") + Number(fee || "0");
    setError(
      totalBalance > Number(balanceInfo || "0") ? "Insufficient balance" : null
    );
  }, [amount, fee]);

  useEffect(() => {
    const initTrezor = async () => {
      try {
        await TrezorConnect.init({
          lazyLoad: false,
          manifest: {
            email: "developer@example.com",
            appUrl: "https://example.com",
          },
        });
        setTrezorConnected(true);
      } catch (error) {
        console.error("Error initializing Trezor:", error);
      }
    };

    initTrezor();

    return () => {
      TrezorConnect.dispose();
    };
  }, []);

  const fetchTokenInfo = useCallback(async () => {
    if (!ethers.isAddress(tokenAddress)) {
      alert("Please enter a valid token address");
      return;
    }

    setIsLoadingTokenInfo(true);
    try {
      const provider = new ethers.JsonRpcProvider(getRpcUrl());
      const contract = new ethers.Contract(tokenAddress, ERC20_ABI, provider);
      const [name, symbol, decimals] = await Promise.all([
        contract.name(),
        contract.symbol(),
        contract.decimals(),
      ]);
      console.log("Token info fetched:", {
        name,
        symbol,
        decimals: Number(decimals),
      });
      setTokenInfo({ name, symbol, decimals: Number(decimals) });
    } catch (error) {
      console.error("Error fetching token info:", error);
      setTokenInfo(null);
      alert(
        "Error fetching token info. Please check the address and try again."
      );
    } finally {
      setIsLoadingTokenInfo(false);
    }
  }, [tokenAddress]);

  // obtener balance de una billetera en un token
  const fetchBalance = async (address: string) => {
    if (!ethers.isAddress(tokenAddress)) {
      alert("Please enter a valid token address");
      return;
    }

    setIsLoadingTokenInfo(true);
    try {
      const provider = new ethers.JsonRpcProvider(getRpcUrl());
      const contract = new ethers.Contract(tokenAddress, ERC20_ABI, provider);
      const balance = await contract.balanceOf(address);
      const decimals = await contract.decimals();
      console.log("Balance fetched:", balance.toString());
      // convertir el balance a la cantidad correcta con decimales
      const balanceFormatted = ethers.formatUnits(balance, decimals);

      setBalanceInfo(balanceFormatted);
    } catch (error) {
      console.error("Error fetching token info:", error);
      setBalanceInfo(null);
      alert(
        "Error fetching token info. Please check the address and try again."
      );
    } finally {
      setIsLoadingTokenInfo(false);
    }
  };

  const connectTrezor = async () => {
    try {
      const result = await TrezorConnect.ethereumGetAddress({
        path: "m/44'/60'/0'/0/0",
        showOnTrezor: true,
      });
      if (result.success) {
        setTrezorAddress(result.payload.address);
        fetchBalance(result.payload.address);
      } else {
        console.error("Failed to get Trezor address:", result.payload.error);
      }
    } catch (error) {
      console.error("Error connecting to Trezor:", error);
    }
  };

  const signTransaction = async () => {
    if (!trezorAddress) {
      alert("Please connect Trezor first");
      return;
    }

    if (!tokenInfo) {
      alert("Invalid token address");
      return;
    }

    try {
      const provider = new ethers.JsonRpcProvider(getRpcUrl());
      const chainId = (await provider.getNetwork()).chainId;

      const tokenContract = new ethers.Contract(
        tokenAddress,
        [
          "function nonces(address owner) view returns (uint256)",
          "function name() view returns (string)",
        ],
        provider
      );

      const nonce = await tokenContract.nonces(trezorAddress);
      const name = await tokenContract.name();

      // Verificar si el token es USDC
      const isUSDC =
        tokenAddress.toLowerCase() ===
        usdcAddresses[network as keyof typeof usdcAddresses].toLowerCase();

      const domain = {
        name: name,
        version: isUSDC ? "2" : "1",
        chainId: Number(chainId),
        verifyingContract: tokenAddress,
      };

      console.log("domain", domain);

      const types = {
        Permit: [
          { name: "owner", type: "address" },
          { name: "spender", type: "address" },
          { name: "value", type: "uint256" },
          { name: "nonce", type: "uint256" },
          { name: "deadline", type: "uint256" },
        ],
      };

      const value = ethers.parseUnits(amount, tokenInfo.decimals);
      const feeValue = ethers.parseUnits(fee, tokenInfo.decimals);
      const totalValue = value + feeValue;

      const message = {
        owner: trezorAddress,
        spender: config.CONTRACT_ADDRESS,
        value: totalValue.toString(),
        nonce: nonce.toString(),
        deadline: Number(deadline),
      };
      const domainSeparator = ethers.TypedDataEncoder.hashDomain(domain);
      const messageHash = ethers.TypedDataEncoder.hashStruct(
        "Permit",
        types,
        message
      );

      const trezorTypedData = {
        types: {
          EIP712Domain: [
            { name: "name", type: "string" },
            { name: "version", type: "string" },
            { name: "chainId", type: "uint256" },
            { name: "verifyingContract", type: "address" },
          ],
          ...types,
        },
        primaryType: "Permit" as const,
        domain: domain,
        message: message,
      };

      const result = await TrezorConnect.ethereumSignTypedData({
        path: "m/44'/60'/0'/0/0",
        data: trezorTypedData,
        metamask_v4_compat: true,
        domain_separator_hash: domainSeparator,
        message_hash: messageHash,
      });

      if (result.success) {
        const signature = result.payload.signature;
        console.log("Raw signature:", signature);

        // Eliminar el '0x' si está presente al principio de la firma
        const cleanSignature = signature.startsWith("0x")
          ? signature.slice(2)
          : signature;

        // Convertir la firma de Trezor al formato que EIP-2612 espera
        const r = "0x" + cleanSignature.slice(0, 64);
        const s = "0x" + cleanSignature.slice(64, 128);
        let v = parseInt(cleanSignature.slice(128, 130), 16);

        // Ajustar v para EIP-2612
        if (v < 27) v += 27;

        console.log("Parsed signature:", { r, s, v });
        setStatus({
          message: "Firmando y procesando...",
          type: "info",
        });

        // Send the signature to your backend
        const response = await fetch(`${config.BACKEND_URL}/relaytrezor`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            environment: network,
            token: tokenAddress,
            sender: trezorAddress,
            receiver,
            amount: value.toString(),
            fee: feeValue.toString(),
            deadline: Number(deadline),
            v,
            r,
            s,
          }),
        });

        const responseData = await response.json();
        if (responseData.error) {
          setStatus({
            message: String(responseData.error),
            type: "error",
          });
        } else {
          console.log("Transaction sent:", responseData);
          setStatus({
            message: `Transaction: ${responseData.txHash}`,
            type: "success",
          });
        }
      } else {
        console.error("Failed to sign message:", result.payload.error);
      }
    } catch (error) {
      console.error("Error signing transaction:", error);
      const errorMessage =
        (error as Error)?.message || "Error signing transaction";
      setStatus({
        message: errorMessage,
        type: "error",
      });
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 py-6 flex flex-col justify-center sm:py-12">
      <div className="relative py-3 sm:mx-auto">
        <div className="absolute inset-0 bg-gradient-to-r from-cyan-400 to-light-blue-500 shadow-lg transform -skew-y-6 sm:skew-y-0 sm:-rotate-6 sm:rounded-3xl"></div>
        <div className="relative px-5 py-10 bg-white sm:rounded-3xl sm:p-20">
          <div className="mx-auto">
            <h1 className="text-3xl font-bold mb-6 text-center text-gray-800">
              Trezor EIP-2771 Signing
            </h1>
            {!trezorConnected ? (
              <p className="text-center text-lg text-gray-600">
                Initializing Trezor connection...
              </p>
            ) : (
              <div className="bg-white shadow-md rounded-lg p-6">
                <button
                  onClick={connectTrezor}
                  className="mb-6 w-full p-3 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition duration-300 ease-in-out"
                >
                  {trezorAddress ? "Reconectar Trezor" : "Conectar Trezor"}
                </button>

                {trezorAddress && (
                  <div className="mb-6 p-4 bg-gray-100 rounded-md">
                    <h2 className="text-lg font-semibold mb-2">
                      Connected Address
                    </h2>
                    <p className="text-sm break-all">{trezorAddress}</p>
                  </div>
                )}

                {tokenInfo && (
                  <div className="mb-6 grid grid-cols-2 gap-4 bg-gray-100 p-4 rounded-md">
                    <div>
                      <h2 className="text-sm font-semibold">Token Name</h2>
                      <p>{tokenInfo.name || "Unknown"}</p>
                    </div>
                    <div>
                      <h2 className="text-sm font-semibold">Symbol</h2>
                      <p>{tokenInfo.symbol || "Unknown"}</p>
                    </div>
                    <div>
                      <h2 className="text-sm font-semibold">Decimals</h2>
                      <p>{tokenInfo.decimals || "Unknown"}</p>
                    </div>
                    <div>
                      <h2 className="text-sm font-semibold">Contract</h2>
                      <p className="text-xs break-all">{tokenAddress}</p>
                    </div>
                    <div>
                      <h2 className="text-sm font-semibold">Balance</h2>
                      <p>
                        {balanceInfo || <i>Conecta tu trezor</i>}{" "}
                        <b>{balanceInfo ? tokenInfo.symbol : ""}</b>
                      </p>
                    </div>
                  </div>
                )}

                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    signTransaction();
                  }}
                  className="space-y-4"
                >
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Network
                    </label>
                    <select
                      value={network}
                      onChange={(e) => setNetwork(e.target.value)}
                      className="w-full p-2 border rounded-md focus:ring-blue-500 focus:border-blue-500"
                    >
                      <option value="sepolia">Sepolia</option>
                      <option value="mainnet">Mainnet</option>
                    </select>
                  </div>
                  <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Token Address
                    </label>
                    <div className="flex">
                      <select
                        value={tokenAddress}
                        onChange={(e) => {
                          setTokenAddress(e.target.value);
                          setBalanceInfo(null);
                        }}
                        className="w-64 grow p-2 border rounded-l-md focus:ring-blue-500 focus:border-blue-500"
                      >
                        {Object.entries(
                          tokenConfig[network as keyof typeof tokenConfig]
                        ).map(([symbol, address]) => (
                          <option key={address} value={address}>
                            {symbol} ({address})
                          </option>
                        ))}
                      </select>
                      <button
                        onClick={fetchTokenInfo}
                        disabled={isLoadingTokenInfo}
                        className={`w-24 px-2 py-2 bg-blue-500 text-white rounded-r-md hover:bg-blue-600 transition duration-300 ease-in-out ${
                          isLoadingTokenInfo
                            ? "opacity-50 cursor-not-allowed"
                            : ""
                        }`}
                      >
                        {isLoadingTokenInfo
                          ? "Loading..."
                          : "Obtener información del token"}
                      </button>
                    </div>
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Receiver Address
                    </label>
                    <input
                      type="text"
                      placeholder="Receiver Address"
                      value={receiver}
                      onChange={(e) => setReceiver(e.target.value)}
                      className="w-full p-2 border rounded-md focus:ring-blue-500 focus:border-blue-500"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Amount
                    </label>
                    <input
                      type="text"
                      disabled={!tokenInfo || !balanceInfo || !trezorAddress}
                      placeholder="Amount"
                      value={amount}
                      onChange={(e) => setAmount(e.target.value)}
                      className="w-full p-2 border rounded-md focus:ring-blue-500 focus:border-blue-500"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Fee
                    </label>
                    <input
                      type="text"
                      disabled={!tokenInfo || !balanceInfo || !trezorAddress}
                      placeholder="Fee"
                      value={fee}
                      onChange={(e) => setFee(e.target.value)}
                      className="w-full p-2 border rounded-md focus:ring-blue-500 focus:border-blue-500"
                    />
                  </div>
                  {error && (
                    <div className="mb-6 p-4 bg-red-100 rounded-md">
                      <h2 className="text-lg font-semibold mb-2">Error</h2>
                      <p className="text-sm">{error}</p>
                    </div>
                  )}
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Expiración de la autorizacion
                    </label>
                    <input
                      type="text"
                      readOnly
                      disabled
                      placeholder="Deadline (Unix timestamp)"
                      value={new Date(Number(deadline) * 1000).toLocaleString()}
                      className="w-full p-2 border rounded-md bg-gray-100 text-gray-600"
                    />
                  </div>
                  {status && (
                    <div
                      className={`mb-6 p-4 rounded-md ${
                        statusClases[status.type] || ""
                      }`}
                    >
                      <h2 className="text-lg font-semibold mb-2">Estado</h2>
                      <p className="text-sm">{status.message}</p>
                    </div>
                  )}
                  <button
                    type="submit"
                    disabled={
                      !trezorAddress ||
                      !tokenInfo ||
                      !receiver ||
                      !amount ||
                      !fee ||
                      !balanceInfo
                    }
                    className={`w-full p-3 bg-green-500 text-white rounded-md hover:bg-green-600 transition duration-300 ease-in-out ${
                      !trezorAddress ||
                      !tokenInfo ||
                      !receiver ||
                      !amount ||
                      !fee ||
                      !balanceInfo
                        ? "opacity-50 cursor-not-allowed"
                        : ""
                    }`}
                  >
                    Firmar y enviar transacción
                  </button>
                </form>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrezorEIP2771Signing;
