interface Config {
    CONTRACT_ADDRESS: string;
    SEPOLIA_RPC_URL: string;
    MAINNET_RPC_URL: string;
    BACKEND_URL: string;
  }
  
  const config: Config = {
    CONTRACT_ADDRESS: import.meta.env.VITE_CONTRACT_ADDRESS,
    SEPOLIA_RPC_URL: import.meta.env.VITE_SEPOLIA_RPC_URL,
    MAINNET_RPC_URL: import.meta.env.VITE_MAINNET_RPC_URL,
    BACKEND_URL: import.meta.env.VITE_BACKEND_URL,
  };
  
  // Validación de configuración
  Object.entries(config).forEach(([key, value]) => {
    if (value === undefined || value === '') {
      console.error(`La variable de entorno ${key} no está definida.`);
    }
  });
  
  export default config;